var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 mt-3"},[(_vm.$route.name == 'manufacturingTransfersCreate')?_c('addHeader',{attrs:{"title":'أضافه عملية نقل بالعمليات',"description":'من هنا يمكنك اضافه نقل بالعمليات'}}):_vm._e(),(_vm.$route.name == 'manufacturingTransfersEdit')?_c('addHeader',{attrs:{"title":'تعديل العملية',"description":'من هنا يمكنك تعديل العملية'}}):_vm._e(),_c('div',{staticClass:"mb-4 row"},[_c('dvider',{attrs:{"title":'المعلومات الأساسية',"description":'المعلومات الاساسية للعملية',"noLine":"true"}}),(_vm.$route.name == 'manufacturingTransfersCreate')?_c('connectionInput',{attrs:{"title":'ترقيم العملية',"group":'المجموعات',"disabled":_vm.$user.admin ||
            _vm.$user.role.store_withdrawals_edit_item
              ? false
              : true,"values":_vm.$database.invoiceGroups,"hasErorr":_vm.errors.invoice_group,"error":'الترقيم مطلوب'},model:{value:(_vm.item.invoice_group),callback:function ($$v) {_vm.$set(_vm.item, "invoice_group", $$v)},expression:"item.invoice_group"}}):_vm._e(),(_vm.$route.name == 'manufacturingTransfersEdit')?_c('formInput',{attrs:{"title":'ترقيم العملية',"disabled":true},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}):_vm._e(),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(" التاريخ ")]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100"},[_vm._m(0),_c('VueCtkDateTimePicker',{attrs:{"label":"إختر الوقت والتاريخ","button-now-translation":"الان","disabled":_vm.$user.admin ||
                  _vm.$user.role.store_withdrawals_edit_item
                    ? false
                    : true,"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)])]),_c('connectionInput',{attrs:{"title":'من مشروع',"group":'المخازن',"values":_vm.$database.Manufactures,"disabled":_vm.$route.name == 'manufacturingTransfersEdit',"hasErorr":_vm.errors.store_id,"error":'هذا الحقل مطلوب'},model:{value:(_vm.item.from_manufacture_id),callback:function ($$v) {_vm.$set(_vm.item, "from_manufacture_id", $$v)},expression:"item.from_manufacture_id"}}),_c('connectionInput',{attrs:{"title":'إلي مشروع',"group":'المخازن',"values":_vm.$database.to_manufacture_id,"disabled":_vm.$route.name == 'manufacturingTransfersEdit',"hasErorr":_vm.errors.store_id,"error":'هذا الحقل مطلوب'},model:{value:(_vm.item.to_store_id),callback:function ($$v) {_vm.$set(_vm.item, "to_store_id", $$v)},expression:"item.to_store_id"}}),_c('connectionInput',{attrs:{"title":'العمليه',"group":'العمليات',"values":_vm.$database.projects},model:{value:(_vm.item.manufacture_id),callback:function ($$v) {_vm.$set(_vm.item, "manufacture_id", $$v)},expression:"item.manufacture_id"}}),_c('connectionInput',{attrs:{"disabled":_vm.item.manufacture_id == 0,"title":'ناتج العمليه',"group":'نواتج العمليه',"values":_vm.$database.projectItems.filter(
              function (el) { return el.manufacture_id == _vm.item.manufacture_id; }
            )},model:{value:(_vm.item.manufacture_item_id),callback:function ($$v) {_vm.$set(_vm.item, "manufacture_item_id", $$v)},expression:"item.manufacture_item_id"}}),(_vm.$route.name == 'manufacturingTransfersCreate')?_c('checkInput',{attrs:{"title":'الطباعة',"value":_vm.print},model:{value:(_vm.print),callback:function ($$v) {_vm.print=$$v},expression:"print"}}):_vm._e(),_c('dvider',{attrs:{"title":'معلومات المنتجات',"description":'معلومات المنتجات الخاصة بالفاتورة'}}),_c('productsTable',{staticClass:"col-12"}),_c('dvider',{attrs:{"title":'تفاصيل العملية',"description":'المعلومات الاضافية الخاصة بالعملية'}}),_c('formTextarea',{attrs:{"title":'وذالك عن'},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('formTextarea',{attrs:{"title":'ملاحظة'},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}}),_c('hr',{staticClass:"mt-5 mb-5 col-12"}),(
            _vm.$route.name == 'manufacturingTransfersCreate' && !_vm.$parent.stopEdit
          )?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":_vm.saveItem}},[_vm._v(" إضافة عمليه ")]):_vm._e(),(_vm.$route.name == 'manufacturingTransfersEdit' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":_vm.saveItem}},[_vm._v(" تعديل العمليه ")]):_vm._e(),(_vm.$parent.stopEdit)?_c('loading'):_vm._e()],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])}]

export { render, staticRenderFns }